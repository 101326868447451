
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
let path_prefix = "/";
if(window.location && window.location.origin){
  if(window.location.origin.includes('bhdp-nft')){
    path_prefix =  "/ocp/";
  }
}
window.appURL= window.location.origin+path_prefix;
import('./bootstrap.js');